import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const aboutStyle = {
  fontFamily: 'Ropa Sans, sans-serif',
  margin: '0 2rem'
};

const About = () => (
  <Layout>
    <SEO title="About" />
    <div style={aboutStyle}>
      <h1 style={{ textAlign: 'center', letterSpacing: '3px' }}>ABOUT</h1>
      <p>Andrew Plan is a Dallas-based, Filipino-American award-winning independent artist and songwriter.
His accolades include placing as a finalist in the 1st annual Kollaboration San Francisco Showcase,
winning the grand prize at the 2014 East Texas Songwriters’ Lab Songwriting Competition, and
placing as a top 3 finalist in the 2017 Lonestar Songcrafters Songwriter Contest. Once an ambitious
pre-med student, Andrew eventually decided against medical school and found a lane for himself as
a different kind of healer. With his smoky-smooth voice, sharp pop melodies and life-affirming lyrics,
Andrew is winning listeners over and carving out his own place in the Dallas music scene and
beyond.</p>
      <p>At the end of 2019 Andrew released his first commercial single, “Supposed to Go”, co-written
      with Selby Copeland and produced by Denny White and Andrew himself. It’s the perfect introduction
      to the journey Andrew’s been on these past several years. “Supposed to Go” captures that first
      spark, that moment he realized he was heading in the wrong direction, and the messy courage that
it took to change course and venture into the unknown to pursue his dreams.</p>
      <p>With every new song released and show performed, Andrew continues to live out his mission to
      heal, inspire and empower others through his music. He opens his heart so you can be emboldened
to open yours and grace the world with the beauty you were made with.</p>
    </div>
  </Layout>
)

export default About
